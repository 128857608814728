import React from 'react';
import { format } from 'date-fns';
import { Bot } from 'lucide-react';

interface ChatMessageProps {
  text: string;
  sender: 'user' | 'assistant';
  timestamp: Date;
  sources?: string[];
}

const ChatMessage: React.FC<ChatMessageProps> = ({ text, sender, timestamp, sources }) => {
  const isUser = sender === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div
        className={`max-w-[80%] md:max-w-[70%] rounded-lg p-4 ${
          isUser
            ? 'bg-blue-600 text-white'
            : 'bg-gray-800 text-gray-100'
        }`}
      >
        {!isUser && (
          <div className="flex items-center mb-2">
            <Bot className="h-5 w-5 mr-2" />
            <span className="font-semibold">StrataAI</span>
          </div>
        )}
        
        <p className="whitespace-pre-wrap break-words">{text}</p>
        
        {!isUser && sources && sources.length > 0 && (
          <div className="mt-3 pt-3 border-t border-gray-600/50">
            <p className="text-sm font-semibold text-gray-300">Sources:</p>
            <div className="mt-1 space-y-1">
              {sources.map((source, index) => (
                <p key={index} className="text-sm text-gray-400 line-clamp-2">
                  {source}
                </p>
              ))}
            </div>
          </div>
        )}
        
        <div className="mt-2">
          <span className="text-xs opacity-70">
            {format(new Date(timestamp), 'HH:mm')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;