// src/config/api.config.ts

export const API_CONFIG = {
    BASE_URL: 'https://smartstrata.tech',  // Updated to use HTTPS
    TIMEOUT: 180000,
    MAX_UPLOAD_SIZE: 500 * 1024 * 1024,
    ENDPOINTS: {
      LOGIN: '/api/login',
      REGISTER: '/api/register',
      CHAT: '/api/chat',
      HEALTH: '/api/health',
      MESSAGES: '/api/messages'
    }
} as const;

export interface ApiResponse<T> {
    data?: T;
    error?: string;
}

export interface ApiRequestConfig {
    method?: string;
    headers?: Record<string, string>;
    body?: string;
    timeout?: number;
}

export const DEFAULT_FETCH_CONFIG: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    mode: 'cors'
};

export default API_CONFIG;
