import React, { useState, useEffect, useRef } from 'react';
import ChatMessage from '../components/ChatMessage';
import ChatInput from '../components/ChatInput';
import { generateResponse, checkHealth } from '../services/api';

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'assistant';
  timestamp: Date;
  sources?: string[];  // Added sources
}

const Dashboard: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isServerReady, setIsServerReady] = useState<boolean | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Auto scroll to bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    let isMounted = true;
    let pollInterval: NodeJS.Timeout | null = null;

    const checkServerStatus = async () => {
      try {
        const result = await checkHealth();
        if (!isMounted) return;
        
        if (result.error) {
          setError(result.error);
          setIsServerReady(false);
          return;
        }

        if (result.data) {
          setIsServerReady(true);
          setError(null);
          // Clear polling if server is ready
          if (pollInterval) {
            clearInterval(pollInterval);
            pollInterval = null;
          }
        }
      } catch (error) {
        if (!isMounted) return;
        setError('Failed to connect to server. Please ensure the server is running.');
        setIsServerReady(false);
      }
    };

    // Initial check
    checkServerStatus();

    // Poll every 3 seconds
    pollInterval = setInterval(checkServerStatus, 3000);

    // Cleanup
    return () => {
      isMounted = false;
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, []);

  const handleSendMessage = async (text: string) => {
    if (!isServerReady) {
      setError('Server is not ready. Please wait for initialization to complete.');
      return;
    }

    if (!text.trim()) return;

    const userMessage: Message = {
      id: Date.now(),
      text,
      sender: 'user',
      timestamp: new Date(),
    };
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);
    setError(null);

    try {
      const result = await generateResponse(text);
      if (result.error) {
        throw new Error(result.error);
      }

      if (result.data) {
        const aiMessage: Message = {
          id: Date.now() + 1,
          text: result.data.response,
          sender: 'assistant',
          timestamp: new Date(),
          sources: result.data.sources  // Add sources if available
        };
        setMessages(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError(error instanceof Error ? error.message : 'Failed to process message');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen pt-16 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4 py-8 flex flex-col h-[calc(100vh-4rem)]">
        {/* Server Status */}
        {isServerReady === false && (
          <div className="bg-yellow-500/10 border border-yellow-500 text-yellow-500 p-4 rounded-lg mb-4 flex items-center">
            <div className="w-2 h-2 bg-yellow-500 rounded-full animate-pulse mr-3" />
            <p>Server is initializing... Please wait.</p>
          </div>
        )}

        {/* Error Display */}
        {error && (
          <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-lg mb-4">
            {error}
          </div>
        )}

        {/* Chat Messages */}
        <div 
          ref={chatContainerRef}
          className="flex-1 overflow-y-auto mb-4 space-y-4 scroll-smooth"
        >
          {messages.length === 0 && isServerReady && !error && (
            <div className="text-center text-gray-400 mt-8">
              <p>Welcome! How can I help you today?</p>
              <p className="text-sm mt-2">Ask me questions about strata management.</p>
            </div>
          )}
          {messages.map((message) => (
            <ChatMessage
              key={message.id}
              text={message.text}
              sender={message.sender}
              timestamp={message.timestamp}
              sources={message.sources}  // Pass sources to ChatMessage
            />
          ))}
          {isLoading && (
            <div className="flex justify-start">
              <div className="bg-gray-800 text-gray-100 rounded-lg p-4">
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" />
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-100" />
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-200" />
                </div>
              </div>
            </div>
          )}
          {/* Scroll anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Chat Input */}
        <ChatInput 
          onSendMessage={handleSendMessage} 
          isLoading={isLoading} 
          disabled={!isServerReady} 
        />
      </div>
    </div>
  );
};

export default Dashboard;