// src/services/api.ts

import {
  API_CONFIG,
  ApiResponse,
  ApiRequestConfig,
  DEFAULT_FETCH_CONFIG
} from '../config/api.config';

const createApiRequest = async <T>(
  endpoint: string,
  config: ApiRequestConfig = {}
): Promise<ApiResponse<T>> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(),
    config.timeout || API_CONFIG.TIMEOUT
  );

  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}${endpoint}`, {
      ...DEFAULT_FETCH_CONFIG,
      method: config.method || 'GET',
      headers: {
        ...DEFAULT_FETCH_CONFIG.headers,
        ...config.headers,
      },
      body: config.body,
      signal: controller.signal,
      credentials: 'include', // Ensure this is set for all requests
      mode: 'cors',          // Ensure this is set for all requests
    });

    const data = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
        window.location.href = '/login';
        throw new Error('Session expired. Please login again.');
      }
      throw new Error(data.error || 'Request failed');
    }

    return { data };
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        return { error: `Request timeout after ${API_CONFIG.TIMEOUT}ms` };
      }
      return { error: error.message };
    }
    return { error: 'An unexpected error occurred' };
  } finally {
    clearTimeout(timeoutId);
  }
};

export const api = {
  checkHealth: async () => {
    return createApiRequest<{
      status: string;
      message: string;
      timestamp: string;
      rag_status: string;
    }>(API_CONFIG.ENDPOINTS.HEALTH);
  },

  login: async (email: string, password: string) => {
    return createApiRequest(API_CONFIG.ENDPOINTS.LOGIN, {
      method: 'POST',
      body: JSON.stringify({ email, password })
    });
  },

  generateResponse: async (message: string) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return { error: 'Not authenticated' };
    }

    return createApiRequest(API_CONFIG.ENDPOINTS.CHAT, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ message })
    });
  }
};

export const { checkHealth, login, generateResponse } = api;
export default api;
