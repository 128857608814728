import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Building } from 'lucide-react';

function App() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black text-white">
      <nav className="fixed top-0 w-full bg-black/90 backdrop-blur-sm border-b border-gray-800 p-4 z-50">
        <div className="container mx-auto flex items-center justify-between">
          <div 
            className="flex items-center space-x-2 cursor-pointer" 
            onClick={() => navigate('/')}
          >
            <Building className="h-8 w-8 text-blue-500" />
            <span className="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
              StrataAI
            </span>
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default App;