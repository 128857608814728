import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 px-4 py-12">
      <div className="max-w-3xl mx-auto">
        {/* Legal Disclaimer Alert */}
        <div className="bg-yellow-500/10 border border-yellow-500 rounded-lg p-6 mb-8">
          <div className="flex items-start">
            <AlertTriangle className="h-6 w-6 text-yellow-500 mt-1 mr-4 flex-shrink-0" />
            <div>
              <h2 className="text-yellow-500 font-semibold text-lg mb-2">Important Legal Disclaimer</h2>
              <p className="text-yellow-100/80">
                The information provided through the StrataAI Assistant is for general informational purposes only and does not constitute legal advice. This service is not a substitute for professional legal counsel. For specific legal advice, please consult with a qualified legal professional in your jurisdiction.
              </p>
            </div>
          </div>
        </div>

        {/* Terms Header */}
        <h1 className="text-4xl font-bold text-white mb-8">Terms and Conditions</h1>
        
        {/* Last Updated */}
        <p className="text-gray-400 mb-8">Last Updated: November 13, 2024</p>

        {/* Terms Sections */}
        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">1. Acceptance of Terms</h2>
            <p className="mb-4">
              By accessing and using the StrataAI Assistant ("Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the Service.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">2. Service Description</h2>
            <p className="mb-4">
              StrataAI Assistant is an AI-powered platform designed to provide information and assistance related to strata management. The Service utilizes artificial intelligence to process queries and provide responses based on available information.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">3. User Responsibilities</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li>You must provide accurate information when using the Service</li>
              <li>You are responsible for maintaining the confidentiality of your account credentials</li>
              <li>You agree not to use the Service for any unlawful purpose</li>
              <li>You will not attempt to circumvent any security measures</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">4. Limitations of Service</h2>
            <p className="mb-4">
              The Service is provided "as is" and "as available." We make no warranties, expressed or implied, regarding the accuracy, completeness, or reliability of the information provided. The AI-generated responses should not be considered as professional or legal advice.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">5. Privacy and Data Usage</h2>
            <p className="mb-4">
              We collect and process information in accordance with our Privacy Policy. By using the Service, you consent to such processing and you warrant that all data provided by you is accurate.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">6. Intellectual Property</h2>
            <p className="mb-4">
              All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are owned by us or our licensors and are protected by intellectual property laws.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">7. Termination</h2>
            <p className="mb-4">
              We reserve the right to terminate or suspend access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">8. Changes to Terms</h2>
            <p className="mb-4">
              We reserve the right to modify these terms at any time. We will notify users of any material changes by posting the new Terms and Conditions on this page.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">9. Contact Information</h2>
            <p className="mb-4">
              For any questions about these Terms and Conditions, please contact us at support@strataai.com
            </p>
          </section>
        </div>

        {/* Back to Dashboard Button */}
        <div className="mt-12 text-center">
          <Link
            to="/"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
          >
            Return to Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
