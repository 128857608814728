import React, { useState, useRef, KeyboardEvent } from 'react';
import { Send, Loader2 } from 'lucide-react';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  isLoading = false,
  disabled = false
}) => {
  const [input, setInput] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading || disabled) return;
    onSendMessage(input.trim());
    setInput('');
    
    // Reset textarea height
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    // Auto-resize textarea
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative bg-gray-800 rounded-lg">
      <textarea
        ref={textAreaRef}
        value={input}
        onChange={handleTextAreaChange}
        onKeyDown={handleKeyDown}
        placeholder={disabled ? "Server initializing..." : "Ask about strata management... (Press Enter to send, Shift+Enter for new line)"}
        disabled={isLoading || disabled}
        rows={1}
        className="w-full bg-transparent text-white rounded-lg pl-4 pr-12 py-4 
                   focus:outline-none focus:ring-2 focus:ring-blue-500 
                   disabled:opacity-50 disabled:cursor-not-allowed
                   resize-none max-h-[200px] min-h-[60px]
                   scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
        style={{
          overflowY: 'auto',
        }}
      />
      <button
        type="submit"
        disabled={isLoading || disabled || !input.trim()}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 
                   text-blue-500 hover:text-blue-400 p-2 
                   disabled:opacity-50 disabled:cursor-not-allowed
                   transition-colors duration-200"
      >
        {isLoading ? (
          <Loader2 className="h-6 w-6 animate-spin" />
        ) : (
          <Send className="h-6 w-6" />
        )}
      </button>
    </form>
  );
};

export default ChatInput;