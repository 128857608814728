import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Building, ArrowRight } from 'lucide-react';

const SplashPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-between relative overflow-hidden">
      {/* Gradient Orbs */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full filter blur-3xl animate-pulse" />
      <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full filter blur-3xl animate-pulse delay-1000" />
      
      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center relative z-10 text-center px-4 pt-20">
        <div className="flex justify-center mb-8">
          <Building className="h-20 w-20 text-blue-500" />
        </div>
        
        <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
          StrataAI Assistant
        </h1>
        
        <p className="text-xl md:text-2xl text-gray-300 mb-12 max-w-2xl">
          Your intelligent companion for strata management, powered by advanced AI technology
        </p>
        
        <button
          onClick={() => navigate('/login')}
          className="group bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-4 rounded-full text-lg font-semibold 
                   hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300 flex items-center justify-center mx-auto"
        >
          Get Started
          <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
        </button>

        {/* Feature Grid */}
        <div className="mt-24 grid grid-cols-1 md:grid-cols-3 gap-8 px-4 max-w-6xl mx-auto">
          {[
            {
              title: "AI-Powered Assistance",
              description: "Get instant answers to your strata management queries"
            },
            {
              title: "Document Analysis",
              description: "Intelligent processing of strata documentation and regulations"
            },
            {
              title: "24/7 Availability",
              description: "Access expert guidance whenever you need it"
            }
          ].map((feature, index) => (
            <div key={index} className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-xl border border-gray-800">
              <h3 className="text-xl font-semibold mb-3 text-blue-400">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full relative z-10 border-t border-gray-800">
        <div className="max-w-6xl mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-500 text-sm mb-4 md:mb-0">
              Strata AI NSW &copy; 2024
            </div>
            <div className="flex space-x-6">
              <Link 
                to="/terms" 
                className="text-gray-400 hover:text-blue-400 transition-colors duration-300 text-sm"
              >
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default SplashPage;
